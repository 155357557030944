import { gql } from '@apollo/client';
import { FaqsQuestionFragment, DisplaySettingFragment } from './elements';
import {
  FooterFragment,
  GlobalMicrodataFragment,
  HeaderFragment,
  NavigationFragment,
  ProductFragment,
  SeoMetadataFragment,
  BannerFragment
} from './fields';
import { ModalFragment } from './modal-sections';
import {
  AccordionFragment,
  BannerMoreInformationFragment,
  BigCardMediaFragment,
  BlogGridFragment,
  CardAndMiniCardGridFragment,
  CardGridFragment,
  CardsFlexibleFragment,
  CheckListFragment,
  ContactFragment,
  CookiebotModificationFragment,
  DropDownListFragment,
  FaqsModuleFragment,
  FeeOrientativeFragment,
  GoogleReviewsFragment,
  GoogleReviewsSelectedFragment,
  HighlightedFragment,
  HorizontalCardListFragment,
  InPageFormFragment,
  MiniCardCollageFragment,
  MiniCardGridFragment,
  MiniCardGridNewFragment,
  NextStepsFragment,
  PageTitleFragment,
  PathologiesFragment,
  PriceTableFragment,
  ReputationGridFragment,
  RichTextFragment,
  SeoContentListFragment,
  ServiceExampleFragment,
  SimpleFragment,
  SliderFragment,
  TaskGridFragment,
  LocationsFragment
} from './sections';

export const GET_SLIDER = gql`
  ${SliderFragment()}
  query getSlider($sliderId: ID!, $locale: String!) {
    sliders(where: { id: $sliderId }, locale: $locale) {
      ...Slider
    }
  }
`;

export const GET_MODALS = gql`
  ${ModalFragment()}
  query getModals($locale: String!) {
    modals(locale: $locale) {
      ...Modal
    }
  }
`;

export const GET_REDIRECTS = gql`
  query GetRedirects($locale: String!) {
    redirect(locale: $locale) {
      entries {
        from {
          slug
          locale
        }
        to {
          slug
          locale
        }
        is_permanent
      }
    }
  }
`;

export const GET_PAGES = gql`
  query GetPages($locale: String!) {
    pages(locale: $locale) {
      id
      slug
      locale
    }
  }
`;

export const GET_BANNERS = gql`
  ${BannerFragment()}
  query getBanners($locale: String!) {
    banners(locale: $locale) {
      ...Banner
    }
  }
`;

export const getPageBySlug = () => {
  return gql`
    ${ModalFragment()}
    ${InPageFormFragment()}
    ${CardAndMiniCardGridFragment()}
    ${CardGridFragment()}
    ${MiniCardGridFragment()}
    ${MiniCardGridNewFragment()}
    ${BigCardMediaFragment()}
    ${MiniCardCollageFragment()}
    ${SeoContentListFragment()}
    ${RichTextFragment()}
    ${SeoMetadataFragment()}
    ${GlobalMicrodataFragment()}
    ${HeaderFragment()}
    ${FooterFragment()}
    ${CheckListFragment()}
    ${HighlightedFragment()}
    ${PageTitleFragment()}
    ${AccordionFragment()}
    ${PriceTableFragment()}
    ${GoogleReviewsFragment()}
    ${NextStepsFragment()}
    ${ReputationGridFragment()}
    ${ContactFragment()}
    ${SimpleFragment()}
    ${BannerMoreInformationFragment()}
    ${FeeOrientativeFragment()}
    ${ServiceExampleFragment()}
    ${FaqsModuleFragment()}
    ${PathologiesFragment()}
    ${GoogleReviewsSelectedFragment()}
    ${DropDownListFragment()}
    ${HorizontalCardListFragment()}
    ${CardsFlexibleFragment()}
    ${CookiebotModificationFragment()}
    ${ProductFragment()}
    ${NavigationFragment()}
    ${FaqsQuestionFragment()}
    ${TaskGridFragment()}
    ${BannerFragment()}
    ${BlogGridFragment()}
    ${DisplaySettingFragment()}
    ${LocationsFragment()}
    query GetPage($slug: String!, $locale: String!) {
      microdatum(locale: $locale) {
        ...GlobalMicrodata
      }
      header(locale: $locale) {
        ...Header
      }
      footer(locale: $locale) {
        ...Footer
      }
      faqsCommon(locale: $locale) {
        faqsQuestions {
          ...FaqsQuestion
        }
      }
      banners(locale: $locale){
        ...Banner
      }
      pages(where: { slug: $slug }, locale: $locale) {
        id
        title
        description
        slug
        locale
        popupModalTimeout
        created_at
        updated_at
        simpleLayout
        localizations {
          slug
          locale
        }
        seoMetadata {
          ...SeoMetadata
        }
        microdata {
          ... on ComponentMicrodataProduct {
            ...Product
          }
        }
        navigation {
          navigation {
            ...Navigation
          }
        }
        popupModal {
          ...Modal
        }
        sections {
          ... on ComponentSectionsSlider {
            slider {
              id
            }
            displaySetting {
              ...DisplaySetting
            }
          }
          ... on ComponentSectionsCardGrid {
            ...CardGrid
          }
          ... on ComponentSectionsMiniCardGrid {
            ...MiniCardGrid
          }
          ... on ComponentSectionsMiniCardGridNew {
            ...MiniCardGridNew
          }
          ... on ComponentSectionsCardAndMiniCardGrid {
            ...CardAndMiniCardGrid
          }
          ... on ComponentSectionsBigCardMedia {
            ...BigCardMedia
          }
          ... on ComponentSectionsMiniCardCollage {
            ...MiniCardCollage
          }
          ... on ComponentSectionsInPageForm {
            ...InPageForm
          }
          ... on ComponentSectionsSeoContentList {
            ...SeoContentList
          }
          ... on ComponentSectionsRichText {
            ...RichText
          }
          ... on ComponentSectionsHighlighted {
            ...Highlighted
          }
          ... on ComponentSectionsPageTitle {
            ...PageTitle
          }
          ... on ComponentSectionsCheckList {
            ...CheckList
          }
          ... on ComponentSectionsAccordion {
            ...Accordion
          }
          ... on ComponentSectionsPriceTable {
            ...PriceTable
          }
          ... on ComponentSectionsGoogleReviews {
            ...GoogleReviews
          }
          ... on ComponentSectionsNextSteps {
            ...NextSteps
          }
          ... on ComponentSectionsReputationGrid {
            ...ReputationGrid
          }
          ... on ComponentSectionsContact {
            ...Contact
          }
          ... on ComponentSectionsSimple {
            ...Simple
          }
          ... on ComponentSectionsBannerMoreInformation {
            ...BannerMoreInformation
          }
          ... on ComponentSectionsFeeOrientative {
            ...FeeOrientative
          }
          ... on ComponentSectionsServiceExample {
            ...ServiceExample
          }
          ... on ComponentSectionsFaqsModule {
            ...FaqsModule
          }
          ... on ComponentSectionsPathologies {
            ...Pathologies
          }
          ... on ComponentSectionsGoogleReviewsSelected {
            ...GoogleReviewsSelected
          }
          ... on ComponentSectionsDropDownList {
            ...DropDownList
          }
          ... on ComponentSectionsHorizontalCardList {
            ...HorizontalCardList
          }
          ... on ComponentSectionsCardsFlexible {
            ...CardsFlexible
          }
          ... on ComponentSectionsCookiebotModification {
            ...CookiebotModification
          }
          ... on ComponentSectionsTaskGrid {
            ...TaskGrid
          }
          ... on ComponentSectionsBlogGrid {
            ...BlogGrid
          }
          ... on ComponentSectionsLocations {
            ...Locations
          }
        }
      }
    }
  `;
};
